<template>
    <Editor
        icon="mdi-check"
        testo-list="Ordinativi da eseguire"
        url="/audit/ordinativo/"
        :args="{stato: 'pianificato'}"
        :fields="fields"
        :filters="filters"
        checkbox
        no-insert
        no-update
        no-delete
        ref="editor"
    >
        <template #item.descrizione="{ item }">
            <div class="descrizione">
                <TextTruncate width="15vw" :text="item.descrizione_lotto"></TextTruncate>
                <a :href="'https://www.google.it/maps/place/' + item.s_referente.indirizzo + ',' + item.s_referente.comune"
                   target="_blank">
                    {{ item.amministrazione }}
                    {{ item.livello_organizzativo_1 }}
                    {{ item.livello_organizzativo_2 }}
                    {{ item.livello_organizzativo_3 }}
                    {{ item.livello_organizzativo_4 }}
                    {{ item.livello_organizzativo_5 }}
                    {{ item.s_referente.indirizzo }}
                    {{ item.s_referente.comune }}
                    {{ item.s_referente.provincia }}

                </a>
                {{ item.s_regione.descrizione }}
            </div>
        </template>
        <template #item.data_pianificazione="{ item }">
            <LocalDate time :date="item.data_pianificazione"></LocalDate>
        </template>

        <template #item.stato_requisiti="{item}">
            {{ item.stato_requisiti.eseguiti }} / {{ item.stato_requisiti.totali }}
        </template>
        <template #actions="{item}">
            <template>
                <Confirm message="Conferma la compilazione?" :item="item" @confirm="conferma"
                         :enabled="!!rapporto">
                    <template #default>
                        <v-file-input v-model="rapporto" label="Rapporto"></v-file-input>
                    </template>
                    <template #activator="{on, attrs}">
                        <v-btn v-on="on" v-bind="attrs" small color="success" class="mr-2">Conferma</v-btn>
                    </template>
                </Confirm>
                <v-btn small color="primary"
                       class="mr-2"
                       @click="download_rapporto(item)">
                    Scarica Rapporto
                </v-btn>
            </template>
            <router-link :to="{name: 'ordinativo_checklist', params: {id: item.id}}">
                <v-btn small color="primary">
                    <v-icon small class="mr-2">mdi-check-circle-outline</v-icon>
                    Compila
                </v-btn>
            </router-link>
        </template>
    </Editor>
</template>

<script>
import Editor from "@/components/Editor";
import LocalDate from "@/components/LocalDate";
import Confirm from "@/components/Confirm";
import TextTruncate from "@/components/TextTruncate.vue";

export default {
    data: () => ({
        fields: [
            {text: 'Convenzione', value: 's_convenzione.nome'},
            {text: 'Ordine', value: 'ordine'},
            {text: 'Lotto', value: 'codice_lotto'},
            {text: 'Descrizione', value: 'descrizione'},
            {text: 'Data', value: 'data_pianificazione'},
            {text: 'Requisiti', value: 'stato_requisiti'},
            {text: 'Ispettore', value: 's_ispettore.full_name'},
        ],
        valida: {
            rapporto: null,
            data: null,
        },
        items: [],
        rapporto: null,
    }),
    computed: {
        filters() {
            return [
                {field: 'convenzione', label: 'Convenzione', type: 'ajax', url: '/backend/autocomplete/convenzione'},
                {
                    field: 'ispettore',
                    label: 'Ispettore',
                    type: 'ajax',
                    url: '/backend/autocomplete/userprofile',
                    filter: {ruoli: 'amministratore,ispettore'}
                },
                {field: 'descrizione', label: 'Descrizione'},
                {field: 'ordine', label: 'Ordine'},
            ]
        },
    },

    methods: {
        async conferma(item) {
            const url = `/audit/ordinativo/${item.id}/conferma/`;
            await this.$http.postFile(url, this.rapporto);
            this.$refs.editor.refresh();
        },
        async valida_rapporto(item) {
            const url = `/audit/ordinativo/${item.id}/valida_rapporto/`;
            await this.$http.postForm(url, {...this.valida});
            this.valida.rapporto = null;
            this.valida.data = null;
            this.$refs.editor.refresh();
        },
        download_rapporto(item) {
            this.$http.downloadFile(`/audit/ordinativo/${item.id}/download_rapporto/`);
        },
    },
    components: {
        TextTruncate,
        Editor,
        LocalDate,
        Confirm,
    },
}
</script>